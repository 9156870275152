<template>
  <div id="dailyReportsContainer">
    <vs-breadcrumb class="mb-5">
      <li>
        <router-link to="/">Domov</router-link>
        <span class="vs-breadcrum--separator">/</span></li>
      <li aria-current="page" class="active">Rezervacije</li>
    </vs-breadcrumb>

    <vs-button radius size="large" color="success" icon-pack="feather" icon="icon-plus-circle" id="addFabBtn"
               title="Dodaj novega uporabnika" @click.stop="addReservation()"></vs-button>


    <vs-row>
      <vs-col vs-type="flex" vs-justify="start" vs-align="start" vs-w="6">
        <vs-button color="primary" size="small" class="mt-1 mr-2" icon="keyboard_arrow_left"
                   @click="() => {selectedDate = moment(selectedDate).subtract(1, 'M').toDate(); onDateChange();}"></vs-button>
        <datepicker :minimumView="'month'" :maximumView="'year'" :language="slSI" :format="customDateFormatter"
                    :monday-first="true"
                    @input="onDateChange" v-model="selectedDate"></datepicker>
        <vs-button color="primary" size="small " class="mt-1 ml-2" icon="keyboard_arrow_right"
                   @click="() => {selectedDate = moment(selectedDate).add(1, 'M').toDate(); onDateChange();}"></vs-button>
      </vs-col>
    </vs-row>

    <div v-if="!reservationsData" class="mt-2">
      <vs-row>
        <vs-col vs-type="flex" vs-justify="start" vs-align="start" vs-w="6">
          <em class="mt-5">Ni vnosov za prikaz</em>
        </vs-col>
      </vs-row>
    </div>

    <div v-if="reservationsData" class="mt-2">
      <div class="vs-component vs-con-table vs-table-primary">
        <header class="header-table vs-table--header">
          <header class="vs-navbar nabarx vs-navbar-null vs-navbar-color-transparent" style="background: transparent;">
            <div class="vs-navbar--header">
              <button class="vs-navbar--btn-responsive"><span class="btn-responsive-line line--1"></span><span
                class="btn-responsive-line line--2"></span><span class="btn-responsive-line line--3"></span></button>
              <div><h3 class="ml-3 py-4">
                Rezervirani prevozi
              </h3></div>
              <div class="ml-3 mt-0 mb-2 cursor-pointer font-bold" v-if="moment(selectedDate).format('MM/YYYY') === moment().format('MM/YYYY')">
                <a @click="jumpToToday()">Pomakni se na današnji dan</a>
              </div>
            </div>
            <div class="vs-con-items"></div>
          </header>
        </header>


        <div class="con-tablex vs-con-table vs-table--content">
          <div class="vs-con-tbody vs-table--tbody mb-10" v-for="(dateItem, dateItemIndex) in reservationsData">
            <table class="vs-table vs-table--tbody-table dailyReportsTable">
              <thead class="vs-table--thead">
              <tr>
                <th colspan="9">
                  <div class="vs-table-text">
                    <h4><b>{{ moment(dateItemIndex, 'DD-MM-YYYY').format('dddd').toUpperCase() }}</b><br/>
                      <small>{{ moment(dateItemIndex, 'DD-MM-YYYY').format('DD. MM. YYYY') }}</small>
                    </h4>

                    <div
                      class="ml-4"
                      v-if="moment(dateItemIndex, 'DD-MM-YYYY').format('DD-MM-YYYY') === moment().format('DD-MM-YYYY')">
                      <vs-chip color="success" id="todayMarker">
                        <vs-avatar icon="star" color="success"/>
                        DANES
                      </vs-chip>
                    </div>

                    <vs-chip color="primary" class="ml-5">
                      <vs-avatar :text="dateItem ? dateItem.length.toString() : '0'" color="primary"/>
                      št. prevozov
                    </vs-chip>

                  </div>
                </th>

              </tr>
              <tr>
                <th style="width: 30px">#</th>
                <th style="width: 90px">ura odh.</th>
                <th style="width: 160px">potnik
                <th style="width: 160px">prostovoljec</th>
                <th style="width: 160px">avto</th>
                <th style="width: 15%">odhod</th>
                <th style="width: 15%">destinacija</th>
                <th style="width: auto">namen</th>
                <th style="width: 250px">akcije</th>
              </tr>
              </thead>
              <tbody>
              <tr class="tr-values vs-table--tr tr-table-state-null"
                  v-for="(reservationItem, reservationItemIndex) in dateItem.slice()"
                  @click.stop="onReservationRowClick(reservationItem)"
                  :class="reservationItem.waiting_for_review ? 'warningBg' : ''"
              >
                <td>{{ reservationItemIndex + 1 }}</td>
                <td>
                  {{ moment(reservationItem.departure_time).format('HH:mm') }}
                </td>
                <td>
                  <div class="vs-col vs-xs- vs-sm- vs-lg- d-flex text-center"
                       style="align-items: flex-start; justify-content: center; width: 160px;">
                    <vs-avatar size="60px" :src="reservationItem.customer.gender === 'male' ? sir : madam"
                               :color="reservationItem.customer.gender === 'male' ? '#dff8ff' : '#ffffdf'"/>
                    <div>{{
                        reservationItem.customer.first_name + ' ' + reservationItem.customer.last_name
                      }}
                    </div>
                  </div>
                </td>
                <td>
                  <div v-if="reservationItem.volunteer_id != null" class="vs-col vs-xs- vs-sm- vs-lg- text-center"
                       style="align-items: flex-start; justify-content: center; width: 160px;">
                    <vs-avatar size="60px"
                               :src="$globalFunctions.getUserAvatar(reservationItem.volunteer.profile_image)"/>
                    <div>
                      {{ reservationItem.volunteer.first_name + ' ' + reservationItem.volunteer.last_name }}
                    </div>
                  </div>
                  <div v-if="reservationItem.volunteer_id == null" class="vs-col vs-xs- vs-sm- vs-lg- text-center"
                       style="align-items: flex-start; justify-content: center; width: 160px;">
                    <vs-avatar size="60px" :text="'?'"/>
                    <div>ni znano</div>
                  </div>
                </td>
                <td>
                  <div class="vs-col vs-xs- vs-sm- vs-lg- text-center"
                       style="align-items: flex-start; justify-content: center; width: 160px;">
                    <vs-avatar size="60px" icon="directions_car"
                               :style="'background:' + getCarsColor(reservationItem.car)"/>
                    <div>{{ getCarsName(reservationItem.car) }}</div>
                  </div>
                </td>
                <td>
                  {{ reservationItem.departure.quick_search.replace(', Slovenija', '') }}
                </td>
                <td>
                  {{ reservationItem.destination.quick_search.replace(', Slovenija', '') }}
                </td>
                <td>

                  <div class="vs-col"
                       style="">

                    <vs-avatar size="60px"
                               v-if="reservationItem.purpose"
                               :icon="$globalFunctions.getReservationPurposeIcon(reservationItem.purpose.id)"
                               :title="reservationItem.purpose.name"/>

                    <vs-avatar size="60px"
                               v-if="!reservationItem.purpose"
                               :text="'/'"
                               :title="'n/a'"/>
                  </div>

                </td>
                <td>
                  <div class="vs-col vs-xs- vs-sm- vs-lg-"
                       style="justify-content: flex-start; display: flex; align-items: center; margin-left: 0%; width: 100%;">
                    <vs-button type="filled" color="success" icon="content_copy" size="small" class="vs-lg-12"
                               @click.stop="duplicateReservation(reservationItem)">Podvoji
                    </vs-button>

                    <div class="split-btn vs-lg-12 ml-5">
                      <vs-button type="filled" color="primary" icon="edit" size="small" class="btnx"
                                 @click.stop="editReservation(reservationItem)">Uredi
                      </vs-button>
                      <vs-dropdown>
                        <vs-button class="btn-drop" type="filled" icon="expand_more" size="small"></vs-button>
                        <vs-dropdown-menu>
                          <vs-dropdown-item @click="() => { markReservationAsCanceled(reservationItem);}">
                            Označi prevoz kot ODPOVEDAN
                          </vs-dropdown-item>
                          <vs-dropdown-item divider @click="() => { markReservationAsDeclined(reservationItem);}">
                            Označi prevoz kot ZAVRNJEN
                          </vs-dropdown-item>
                        </vs-dropdown-menu>
                      </vs-dropdown>

                    </div>

                    <div class="split-btn vs-lg-12 ml-5">
                      <vs-dropdown>
                        <vs-button color="warning" type="filled" icon="phone" size="small">Pokliči</vs-button>
                        <vs-dropdown-menu>
                          <vs-dropdown-item :href="'tel://' + reservationItem.customer.phone">
                            Pokliči UPORABNIKA
                          </vs-dropdown-item>
                          <vs-dropdown-item divider :href="'tel://' + reservationItem.volunteer.phone" v-if="reservationItem.volunteer && reservationItem.volunteer.phone">
                            Pokliči PROSTOVOLJCA
                          </vs-dropdown-item>
                        </vs-dropdown-menu>
                      </vs-dropdown>

                    </div>

<!--                    <vs-button type="filled" color="primary" icon="edit" size="small" class="vs-lg-12 ml-5"-->
<!--                               @click.stop="editReservation(reservationItem)">Uredi-->
<!--                    </vs-button>-->

                    <vs-button type="filled" color="danger" icon="delete" size="small" class="vs-lg-12 ml-5"
                               @click.stop="beforeDeleteReservation(reservationItem)">Izbriši
                    </vs-button>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>

      </div>
    </div>

    <div id="parentx">
      <vs-sidebar position-right parent="body" default-index="1" color="primary" class="unitSidebar" spacer
                  v-model="sidebarActive">

        <Reservation v-if="selectedReservationId" :reservationId="selectedReservationId" @closeSideBar="closeSideBar"/>

      </vs-sidebar>
    </div>

  </div>
</template>

<script>
import Vue from 'vue';
import Datepicker from 'vuejs-datepicker';
import {slSI} from 'vuejs-datepicker/dist/locale';
import sir from "@/assets/images/portrait/sir.svg";
import madam from "@/assets/images/portrait/madam.svg";
import Reservation from '../../components/Sopotniki/reservations/Reservation';

export default {
  name: 'Reservations',

  components: {
    Datepicker,
    Reservation
  },

  data() {
    return {
      slSI: slSI,
      sir: sir,
      madam: madam,
      reservationsData: null,
      selectedReservationId: null,
      sidebarActive: false,
      selectedDate: new Date(),
      mode: null,
    }
  },

  computed: {},

  async mounted() {
    const _this = this;
    this.selectedDate = await this.$store.getters.getSelectedDateForReservations;
    _this.getReservations();
  },

  beforeRouteLeave(to, from, next) {
    console.log("beforeRouteLeave");
    const _this = this;
    _this.selectedReservationId = null;
    _this.sidebarActive = false;
    setTimeout(() => {
      next()
    }, 100);
  },

  methods: {
    getReservations() {
      const _this = this;

      _this.$vs.loading();

      _this.events = [];

      let month = _this.moment(_this.selectedDate).month() + 1;
      let year = _this.moment(_this.selectedDate).year();
      console.log("month", month);
      console.log("year", year);

      console.log("_this.$globalFunctions.getCurrentUnit", _this.$globalFunctions.getCurrentUnit().id);

      const groupByDate = item => _this.moment(item.departure_time).format('DD-MM-YYYY');

      Vue.prototype.$http.get(Vue.prototype.$config.api.productionOld + 'dashboard/' + _this.$globalFunctions.getCurrentUnit().id + '/' + year + '/' + month)
        .then((res) => {
          _this.reservationsData = _.groupBy(_.sortBy(res.data.data, function (o) {
            return _this.moment(o.departure_time).valueOf();
          }), groupByDate);
          console.log("_this.reservationsData", _this.reservationsData);
          _this.$vs.loading.close();
        })
        .catch((error) => {
          console.log("error", error);
          _this.$vs.loading.close();
          return false
        });
    },

    onDateChange() {
      this.$store.dispatch('setSelectedDateForReservations', this.selectedDate);
      this.getReservations();
    },

    getCarsColor(carData) {
      const _this = this;

      if (carData && _this.$globalFunctions.getCarData(carData.id)) {
        return _this.$globalFunctions.getCarData(carData.id).color
      } else {
        return 'grey'
      }
    },

    getCarsName(carData) {
      const _this = this;

      if (carData && _this.$globalFunctions.getCarData(carData.id)) {
        return _this.$globalFunctions.getCarData(carData.id).name
      } else {
        return 'ni določeno'
      }
    },

    customDateFormatter(date) {
      return this.moment(date).format('MMMM, YYYY');
    },

    onReservationRowClick(reservation) {
      const _this = this;

      _this.selectedReservationId = parseInt(reservation.id);
      _this.sidebarActive = true;
    },

    closeSideBar(reloadReservations) {
      const _this = this;

      _this.selectedReservationId = null;
      _this.sidebarActive = false;

      if (reloadReservations) {
        _this.getReservations();
      }
    },

    duplicateReservation(reservationItem) {
      const _this = this;

      _this.$vs.loading();
      let reservation = reservationItem;

      reservation.id = null;
      reservation.created_at = new Date().toISOString();
      reservation.updated_at = new Date().toISOString();
      // reservation.departure_time = new Date().toISOString();
      // reservation.arrival_time = new Date().toISOString();
      reservation.volunteer_id = null;
      reservation.departure_address = {
        id: reservation.departure.id, //Update
        quick_search: (typeof reservation.departure.quick_search === "undefined" ? null : reservation.departure.quick_search.formatted_address) || reservation.departure.quick_search, //reservation.departure.quick_search.formatted_address || reservation.departure.quick_search,
        address_name: reservation.departure.address_name,
        route: reservation.departure.route,
        street_number: reservation.departure.street_number,
        postal_code: reservation.departure.postal_code,
        postal_town: reservation.departure.postal_town,
        municipality: reservation.departure.municipality,
        country: reservation.departure.country
      };
      reservation.arrival_address = {
        id: reservation.arrival.id, //Update
        quick_search: (typeof reservation.arrival.quick_search === "undefined" ? null : reservation.arrival.quick_search.formatted_address) || reservation.arrival.quick_search, //reservation.arrival.quick_search.formatted_address || reservation.arrival.quick_search,
        address_name: reservation.arrival.address_name,
        route: reservation.arrival.route,
        street_number: reservation.arrival.street_number,
        postal_code: reservation.arrival.postal_code,
        postal_town: reservation.arrival.postal_town,
        municipality: reservation.arrival.municipality,
        country: reservation.arrival.country
      };

      console.log("reservation after: ", reservation);


      Vue.prototype.$http.post(Vue.prototype.$config.api.productionOld + 'reservations/', reservation)
        .then((res) => {

          console.log("res", res.data);
          _this.$vs.loading.close();
          _this.$router.push({
            name: 'Rezervacija',
            params: {reservationId: res.data.data.id, createdViaDuplicateMethod: true}
          });

        })
        .catch((error) => {
          console.log("error", error);
          _this.$vs.loading.close();
          return false
        });
    },

    beforeDeleteReservation(reservationItem) {
      const _this = this;

      Vue.$confirm({
        title: 'Ste prepričani, da želite izbrisati rezervacijo uporabnika ' + reservationItem.customer.first_name + ' ' + reservationItem.customer.last_name + ' ?',
        message: 'Po potrditvi bo rezervacija trajno izbrisana.',
        button: {
          yes: 'Izbriši',
          no: 'Prekliči'
        },
        callback: confirm => {
          console.log("confirm", confirm);
          if (confirm) {
            _this.deleteReservation(reservationItem);
          }
        }
      });
    },

    deleteReservation(reservationItem) {
      const _this = this;

      _this.$vs.loading();

      Vue.prototype.$http.delete(Vue.prototype.$config.api.productionOld + 'reservations/' + reservationItem.id)
        .then((res) => {

          _this.$vs.loading.close();
          _this.getReservations();

        })
        .catch((error) => {
          console.log("error", error);
          _this.$vs.loading.close();
          return false
        });
    },

    editReservation(reservationItem) {
      const _this = this;

      _this.$router.push({name: 'Rezervacija', params: {reservationId: reservationItem.id}});
    },

    addReservation() {
      const _this = this;

      _this.$router.push({name: 'Rezervacija'})
    },

    markReservationAsCanceled(reservationItem) {
      const _this = this;
      _this.$router.push({name: 'CanceledReservationEdit', params: {reservationItem: reservationItem}});
    },

    markReservationAsDeclined(reservationItem) {
      const _this = this;
      _this.$router.push({name: 'DeclinedReservationEdit', params: {reservationItem: reservationItem}});
    },

    jumpToToday() {
      let element = document.getElementById("todayMarker");
      let top = this.getOffset(element).top - 100;
      window.scrollTo(  {top,
        left: 0,
        behavior: 'smooth'});
    },

    getOffset(el) {
      const rect = el.getBoundingClientRect();
      return {
        left: rect.left + window.scrollX,
        top: rect.top + window.scrollY
      };
    }

  }
}
</script>

